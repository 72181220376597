<template>
  <v-container class="payment" :class="{ 'w-90': $vuetify.breakpoint.lgAndUp }">
    <div class="checkout-title">
      <CategoryTitle
        :category="category"
        :showOnMobile="true"
        :centered="$vuetify.breakpoint.xs"
        :showDescription="false"
      />
    </div>
    <v-container class="pa-0">
      <v-row>
        <v-col
          v-if="categoryDescription"
          class="categoryDescription mt-3 px-0 pl-md-3"
          cols="12"
          order="1"
        >
          <div class="content" v-html="categoryDescription" />
        </v-col>
        <v-col cols="12" md="3" lg="4" order="1" order-md="2" class="pt-0">
          <div class="mb-3 px-3 px-sm-0">
            <div class="mb-3">
              <CartInfoAddressCard
                class="w-100"
                :shippingAddress="cart.shippingAddress"
                :editable="false"
                :isCheckout="true"
              />
            </div>

            <div>
              <CartInfoTimeslotCard
                class="w-100 cart-info-timeslot-card"
                :shippingAddress="cart.shippingAddress"
                :timeslot="cart.timeslot"
                :editable="false"
                :isCheckout="true"
                :value="timeslot"
              />
            </div>
          </div>

          <CheckoutSummary class="accent mb-6 mx-3 mx-sm-0" :orderCart="cart" />
        </v-col>
        <v-col cols="12" md="9" lg="8" class="pt-0" order="2" order-md="1">
          <div class="mb-3 px-3 px-sm-0">
            <h4 class="secondary--text text-uppercase">
              {{ $t("paymentInvoice.billingInformation") }}
            </h4>
            <div>
              <label
                class="font-weight-bold"
                v-html="`${$t('paymentInvoice.company')}`"
              />
              <v-text-field
                v-model="user.person.company"
                dense
                outlined
                flat
                hide-details
                disabled
              ></v-text-field>
            </div>
            <div class="pt-2">
              <label
                class="font-weight-bold"
                v-html="`${$t('paymentInvoice.vatCode')}`"
              />
              <v-text-field
                v-model="user.person.vatCode"
                dense
                outlined
                flat
                hide-details
                disabled
              ></v-text-field>
            </div>
            <div class="pt-2">
              <label
                class="font-weight-bold"
                v-html="`${$t('paymentInvoice.address')}`"
              />
              <v-text-field
                :value="fullAddress"
                dense
                outlined
                flat
                hide-details
                disabled
              ></v-text-field>
            </div>
            <v-divider class="mt-8 mb-7"></v-divider>
            <div>
              <v-form
                ref="secondaryemailform"
                v-model="isSecondaryEmailFormValid"
              >
                <h4 class="secondary--text text-uppercase">
                  {{ $t("paymentInvoice.addSecondaryEmail") }}
                </h4>
                <v-text-field
                  v-model="newSecondaryEmail"
                  :placeholder="`${$t('paymentInvoice.secondaryEmail')}`"
                  outlined
                  dense
                  :rules="emailRules"
                />
                <v-btn
                  color="primary"
                  depressed
                  :disabled="!isSecondaryEmailFormValid"
                  @click="updateSecondaryEmail()"
                  :loading="loadingSecondaryEmailChange"
                >
                  {{ $t("profile.save") }}
                </v-btn>
              </v-form>
            </div>
            <v-divider class="mt-8 mb-7"></v-divider>
            <h4 class="secondary--text text-uppercase">
              {{
                cart.cartStatusId == 7
                  ? $t("paymentInvoice.paymentMethodChosen")
                  : $t("paymentInvoice.choosePaymentMethod")
              }}
            </h4>
            <p>
              <strong>{{ $t("paymentInvoice.orderObligation") }}</strong>
            </p>
            <PaymentTypeList
              class="mx-3 mx-sm-0"
              :options="{
                showRememberCard: false,
                showPaymentMethodImage: false,
                showCardLogos: true,
                orderAmount: cart.totalPrice,
                showPrebilledAmountInfoText: true,
                mainBackgroundColor: 'transparent',
                confirm: { color: 'primary' },
                back: {
                  outline: true,
                  class: 'secondary--text'
                },
                titleStyle: 'secondary--text text--darken-2 text-body-1 mb-2'
              }"
              :isPaymentDisabled="!billingFormCompletedInternal"
              :paymentNameToPaymentInfo="true"
            />
            <RecaptchaDisclaimer /></div
        ></v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<style scoped lang="scss">
.payment {
  .v-stepper {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: 80%;
    }
  }
  .cart-info-timeslot-card {
    .value {
      text-transform: uppercase;
    }
  }
  .categoryDescription {
    .content {
      background-color: $white;
    }
    :deep(h2) {
      font-size: 14px;
      background-color: var(--v-default-lighten1);
      text-transform: uppercase;
      padding: 10px;
      line-height: 1.5;
    }
    :deep(p) {
      padding: 10px;
      font-size: 14px;
      color: var(--v-default-base);
    }
  }

  .payment-methods {
    background-color: transparent !important;
    :deep(.v-expansion-panel) {
      background-color: $white !important;
      border: 1px solid $border-color;
      padding: 0;
      border-radius: $border-radius-root;
      margin-bottom: 20px;
      &.v-expansion-panel--active {
        border-radius: $border-radius-root;
      }
      &:last-child {
        margin-bottom: 0px;
        border-top: none;
      }
      &::before {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
      }
      .v-expansion-panel-header {
        background-color: $white;
        border-radius: $border-radius-root !important;
        margin-bottom: 5px;
        label {
          display: flex;
          align-items: center;
          padding-left: 40px;
        }
        .payment-method-img {
          height: 50px !important;
        }
      }
      .v-expansion-panel-content__wrap {
        padding: 12px 24px !important;
        border-radius: $border-radius-root !important;

        .prebilled-amount {
          margin-bottom: 20px;
        }
        .caption {
          margin-top: 20px;
        }
      }

      .prebilledAmountInfo {
        margin-bottom: 20px;
        .text {
          color: var(--v-primary-lighten1);
        }
        .sub {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";

import AnalyticsService from "~/service/analyticsService";
import userService from "~/service/userService";

import productMixins from "~/mixins/product";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";
import RecaptchaDisclaimer from "~/components/RecaptchaDisclaimer.vue";

import { requiredValue, isEmail } from "~/validator/validationRules";
import cloneDeep from "lodash/cloneDeep";

import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Checkout",
  mixins: [login, productMixins, cartInfo, categoryMixins],
  components: {
    CategoryTitle,
    CheckoutSummary,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    PaymentTypeList,
    RecaptchaDisclaimer
  },
  data() {
    return {
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      error: null,
      loading: false,
      billingFormCompletedInternal: true,
      emailKey: 1,
      showAlternativesTooltip: false,
      valid: false,
      sellCondition: false,
      isSecondaryEmailFormValid: false,
      loadingSecondaryEmailChange: false,
      newSecondaryEmail: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      user: "cart/getUser"
    }),
    categoryDescription() {
      return this.$ebsn.meta(this.category, "category_info.DESCRIPTION");
    },
    fullAddress() {
      return (
        this.user.billingAddress.address1 +
        ", " +
        this.user.billingAddress.postalcode +
        " " +
        this.user.billingAddress.city +
        " (" +
        this.user.billingAddress.province +
        ")"
      );
    }
  },

  methods: {
    ...mapActions({
      lockCart: "cart/lockCart"
    }),
    billingFormCompleted(completed) {
      this.billingFormCompletedInternal = completed;
    },
    async goToPayment() {
      let vm = this;
      vm.loading = true;
      try {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            await vm.lockCart();
            vm.$router.push({
              name: "Payment"
            });
          }
        }
      } catch (err) {
        await this.needTimeslot();
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    },
    async updateSecondaryEmail() {
      let _this = this;
      let userData = cloneDeep(this.user);
      try {
        _this.response = {};
        _this.loadingSecondaryEmailChange = true;
        if (_this.$refs.secondaryemailform.validate()) {
          if (!userData.contact) {
            userData.contact = {};
          }
          userData.contact.email2 = _this.newSecondaryEmail;
          let res = await userService.updateContact(userData);
          _this.loadingSecondaryEmailChange = false;
          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingSecondaryEmailChange = false;
      }
    }
  },
  mounted() {
    this.newSecondaryEmail = this.user.contact.email2;
    AnalyticsService.beginCheckout(this.cart);
  }
};
</script>
